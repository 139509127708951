import {db} from "../main";
import firebase from "../../firebase";

function getUsers(onSuccessCallback, onFailedCallback, onErrorCallback) {
    let query = db
        .collection("users");
    query.get().then(function (querySnapshot) {
            let results = [];
            querySnapshot.forEach(function (doc) {
                let id = doc.id;
                let data = doc.data();

                results.push(data);
            });

            if (onSuccessCallback != null)
                onSuccessCallback(results);
        },
        function (error) {
            console.log(error);
            if (onErrorCallback != null)
                onErrorCallback(error);
        }
    );
}

function getEstablishments(onSuccessCallback, onFailedCallback, onErrorCallback) {
    let query = db
        .collection("benefits/membership_card/establishments");
    query.get().then(function (querySnapshot) {
            let results = [];
            querySnapshot.forEach(function (doc) {
                let id = doc.id;
                let data = doc.data();

                results.push(data);
            });

            if (onSuccessCallback != null)
                onSuccessCallback(results);
        },
        function (error) {
            console.log(error);
            if (onErrorCallback != null)
                onErrorCallback(error);
        }
    );
}

function updateEstablishment(establishmenId, establishmenData, successCallback, failedCallback) {
    let docRef = db
        .collection("benefits")
        .doc("membership_card")
        .collection("establishments")
        .doc(establishmenId);
    docRef.update(establishmenData).then(() => {
        console.log('success');
        if(successCallback != null)
            successCallback();
    }).catch((error) => {
        if(failedCallback != null)
            failedCallback(error);
    });
}

//  TODO: pending!
function getEstablishment(establishmenId, onSuccessCallback, onFailedCallback, onErrorCallback) {
    let docRef = db
        .collection("benefits")
        .doc("membership_card")
        .collection("establishments")
        .doc(establishmenId);
    docRef.get().then(function (doc) {
        if(doc.exists) {
            if (onSuccessCallback != null)
                onSuccessCallback(doc.data());
        } else {
            if(onFailedCallback != null)
                onFailedCallback();
        }
/*
            if (!that.editMode && doc.exists) {
                console.log("Document data:", doc.data());

                that.$toasted.show("Establishment already exists!", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");

                docRef
                    .set(establimentData)
                    .then(function () {
                        console.log("Document successfully written!");
                        that.clearForm();

                        that.$toasted.show("Successful", {
                            theme: "bubble",
                            position: "top-center",
                            duration: 2000
                        });

                        that.$emit('closePanel', {});
                    })
                    .catch(function (error) {
                        console.error("Error writing document: ", error);
                    });
            }
*/
        },
        function (error) {
            console.log(error);
            if (onErrorCallback != null)
                onErrorCallback(error);
        }
    );
}

export {
    getUsers,
    getEstablishment,
    getEstablishments,
    updateEstablishment
}
