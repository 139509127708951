<template>
    <div class="single-box-col-9 main-dashboard-establishments">
        <div class="md-layout headers">
            <div class="md-layout-item">
                <h2>Details</h2>
            </div>
            <div class="md-layout-item">
                <b-button pill class="btn" type="button" @click="save">Save</b-button>
            </div>
            <div class="md-layout-item">
                <b-button pill class="btn" type="button" @click="back">Back</b-button>
            </div>
        </div>
        <hr>
        <div class="md-layout md-gutter">
            <div class="md-layout-item">
                <md-card class="details-card">
                    <div class="flexbox flex-top wrap establishment-details">
                        <div class="single-box-col-2">
                            <md-card-media>
                                <img class="dashboard-card-media establishment-logo" :src="establishment_logo">
                            </md-card-media>
                        </div>
                        <div class="single-box-col-4 column-details">
                            <md-card-header-text>
                                <h5>{{establishment_name}}</h5>
                            </md-card-header-text>
                            <div class="md-layout flexbox flex-center wrap column-details-row">
                                <div class="md-layout-item single-box-col-4 establishment-label">
                                    Category:
                                </div>
                                <div class="md-layout-item single-box-col-8">
                                    <md-select v-model="establishment_category">
                                        <md-option v-for="_category in categories" :value="_category.value">{{_category.name}}</md-option>
                                    </md-select>
                                </div>
                            </div>
                            <div class="md-layout flexbox flex-center wrap column-details-row">
                                <div class="md-layout-item md-layout-item single-box-col-4 establishment-label">
                                    Address:
                                </div>
                                <div class="md-layout-item md-layout-item single-box-col-8 establishment-text">
                                    <md-input v-model="establishment_fullAddr"></md-input>
                                </div>
                            </div>
                            <div class="md-layout flexbox flex-center wrap column-details-row">
                                <div class="md-layout-item single-box-col-4 establishment-label">
                                    Suburb:
                                </div>
                                <div class="md-layout-item single-box-col-8 establishment-text">
                                    <md-input v-model="establishment_suburb"></md-input>
                                </div>
                            </div>
                            <div class="md-layout flexbox flex-center wrap column-details-row">
                                <div class="md-layout-item single-box-col-4 establishment-label">
                                    City:
                                </div>
                                <div class="md-layout-item single-box-col-8 establishment-text">
                                    <md-input v-model="establishment_city"></md-input>
                                </div>
                            </div>
                            <div class="md-layout flexbox flex-center wrap column-details-row">
                                <div class="md-layout-item single-box-col-4 establishment-label">
                                    Post Code:
                                </div>
                                <div class="md-layout-item single-box-col-8 establishment-text">
                                    <md-input v-model="establishment_postalCode"></md-input>
                                </div>
                            </div>
                            <div class="md-layout flexbox flex-center wrap column-details-row">
                                <div class="md-layout-item single-box-col-4 establishment-label">
                                    State:
                                </div>
                                <div class="md-layout-item single-box-col-8 establishment-text">
                                    <md-input v-model="establishment_state"></md-input>
                                </div>
                            </div>
                        </div>
                        <div class="single-box-col-6">
                            <md-card-header-text class="map">
                                <GoogleMap
                                        ref="googleMap"
                                        style="height: 100% !important;"
                                        :mapConfig="mapConfig"
                                        :apiKey="apiKey"
                                ></GoogleMap>
                            </md-card-header-text>
                        </div>
                    </div>
                </md-card>
            </div>
        </div>
        <div style="height: 20px;"></div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item">
                <md-card class="details-card">
                    <md-card-header-text>
                        <h5>Benefits</h5>
                    </md-card-header-text>
                    <div class="md-layout">
                        <div class="md-layout-item">
                            <carousel>
                                <slide v-for="benefit in establishment_benefits">
                                    <img class="dashboard-card-media establishment-benefit-img" :src="benefit.img">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            {{benefit.name}}
                                        </div>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div>
                </md-card>
            </div>
            <div class="md-layout-item">
                <md-card class="details-card">
                    <md-card-header-text>
                        <div class="md-layout">
                            <div class="md-layout-item">
                                <h5>QR Code</h5>
                            </div>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item">
                                <qrcode :value="qrcode" :options="{ width: 120 }"></qrcode>
                            </div>
                            <div class="md-layout-item">
                                <b-button pill class="btn" type="button" @click="askCreateBarcode">Create new</b-button>
                            </div>
                        </div>
                    </md-card-header-text>
                </md-card>
            </div>
        </div>

        <!--
                <md-dialog-confirm
                        :md-active.sync="showLogoutDialog"
                        md-title="Log out"
                        md-content="Are you sure you want to log out?"
                        md-confirm-text="Yes"
                        md-cancel-text="No"
                        @md-confirm="logout"/>
        -->

        <md-dialog-confirm
                :md-active.sync="showChangeQRCode"
                md-title="Change QR Code"
                md-content="Are you sure you want to change the QR code?"
                md-confirm-text="Yes"
                md-cancel-text="No"
                @md-confirm="changeBarcode"/>

    </div>
</template>

<script>
    import GoogleMap from '../../maps/GoogleMap';
    import {mapSettings, API_KEY} from '@/constants/mapSettings';

    import {bus, db} from "@/main";
    import {
        updateEstablishment,
        getEstablishment
    } from "../../../core/firestore";
    import router from "@/router";

    import {v5 as uuidv5} from 'uuid';
    import moment from "moment";

    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: "Details",
        components: {
            GoogleMap,
            Carousel,
            Slide,
        },
        computed: {
            apiKey() {
                return API_KEY;
            },

            mapConfig() {
                return {
                    ...mapSettings,
                    center: this.mapCenter
                };
            },

            mapCenter() {
                return {
                    lat: -37.8136276,
                    lng: 144.9630576
                };
            },
        },
        data() {
            return {
                categories: [
                    {
                        name: "Health and Fitness",
                        value: "health_and_fitness"
                    },
                    {
                        name: "Restaurants",
                        value: "restaurants"
                    },
                ],
                establishment_id: null,
                establishment_name: null,
                establishment_category: null,
                establishment_fullAddr: null,
                establishment_city: null,
                establishment_suburb: null,
                establishment_postalCode: null,
                establishment_state: null,
                establishment_countryGroup: null,
                establishment_benefits: null,
                establishment_logo: null,
                establishment_lat: null,
                establishment_lng: null,

                showLogoutDialog: false,
                showChangeQRCode: false,
                qrcode: 'erwin omar',
            }
        },
        created() {
            let that = this;

            this.establishment_id = this.$route.query.id;
            console.log(this.establishment_id);

            getEstablishment(this.establishment_id, function (establishment) {
                console.log(establishment);

                that.establishment_name = establishment.name;
                that.establishment_category = establishment.category;
                that.establishment_fullAddr = establishment.address;
                that.establishment_suburb = establishment.suburb;
                that.establishment_city = establishment.city;
                that.establishment_postalCode = establishment.postal_code;
                that.establishment_state = establishment.state;
                that.establishment_countryGroup = establishment.country;

                that.establishment_logo = establishment.logo;
                that.establishment_lat = establishment.lat;
                that.establishment_lng = establishment.lng;

                that.establishment_benefits = establishment.benefits;

                that.$refs.googleMap.drawMarkers([{
                    type: 'DRAGGABLE',
                    position: {
                        lat: that.establishment_lat,
                        lng: that.establishment_lng
                    }
                }]);
                that.$refs.googleMap.fitMarkers();
            }, null, null);
        },

        async mounted() {
            let that = this;

        },

        methods: {
            askCreateBarcode() {
                console.log("askCreateBarcode()");
                this.showChangeQRCode = true;
            },

            changeBarcode() {
                console.log("changeBarcode()");
                let that = this;
                let millis = parseInt(moment().format("X")) * 1000;
                let barcode = uuidv5('aussiety_' + millis, uuidv5.DNS).substring(0, 8);
                let establishmentData = {
                    updated: new Date(),
                    barcode: barcode
                };
                console.log(establishmentData);
                updateEstablishment(this.establishment_id, establishmentData, () => {
                    console.log('success');
                    that.qrcode = barcode;
                }, null);
            },

            back() {
                console.log('back');
                this.$router.go(-1);
            },

            save() {
                console.log('save');
                let that = this;
                let establishmentData = {
                    updated: new Date(),
                    address: this.establishment_fullAddr,
                    city: this.establishment_city,
                    suburb: this.establishment_suburb,
                    postal_code: this.establishment_postalCode,
                    state: this.establishment_state,
                };
                updateEstablishment(this.establishment_id, establishmentData, () => {
                    console.log('success');
                    that.$router.go(-1);
                }, null);
                // this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
    .establishment-logo {
        max-width: 220px;
        max-height: 220px;
    }

    .establishment-label {
        color: black;
        flex: unset;
    }

    .establishment-text {
        color: gray;
    }

    .establishment-benefit-img {
        max-height: 220px;
    }

    .details-card {
        padding: 35px;
    }

    .map {
        height: 320px;
    }

    .main-dashboard-establishments {
        padding: 50px;
    }

    .main-dashboard-establishments .headers {
        margin-bottom: 10px;
    }

    .establishment-details h5 {
        margin-bottom: 20px;
        font-weight: 400;
    }

    .establishment-details .md-input, .establishment-details .md-select, .establishment-details .md-select-value, .establishment-details input.category {
        width: 100%;
        max-width: 300px;
        border: none;
        border-bottom: 1px solid;
        color: var(--gray);
    }

    .establishment-details .column-details {
        padding: 0 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    
    .establishment-details .column-details .column-details-row {
        height: 40px;
    }

</style>