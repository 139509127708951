<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <!--    <template v-if="Boolean(this.google) && Boolean(this.map)">-->
    <template v-if="Boolean(this.map)">
      <slot
        :map="map"
        :markers="markers"
        :lines="lines"
      />
    </template>
  </div>
</template>

<script>
  import GoogleMapsApiLoader from 'google-maps-api-loader';
  import {googlePromise} from "../../main";

  import {POINT_MARKER_ICON_CONFIG, LINE_PATH_CONFIG} from "@/constants/mapSettings";

  export default {
    name: "GoogleMapLoader",

    props: {
      mapConfig: Object,
      apiKey: String,
    },

    data() {
      return {
        // google: google,
        map: null,

        markers: [],
        lines: [],
      }
    },

    async mounted() {
      this.initializeMap();
    },

    methods: {
      clearPaths(tag = null) {
        let rebuilt = [];
        this.lines.forEach(function (line) {
          if (tag == null) {
            line.line.setMap(null);
          } else {
            if (line.tag != null && line.tag == tag)
              line.line.setMap(null);
            else
              rebuilt.push(line);
          }
        });
        if (tag == null)
          this.lines = [];
        else
          this.lines = rebuilt;
      },

      drawPath(path, tag = null) {
        let that = this;

        let line = new google.maps.Polyline({
          path: path.path,
          map: this.map,

          strokeColor: '#FF7700',
          strokeOpacity: 1.0,
          strokeWeight: 4,

          // ...LINE_PATH_CONFIG
        });

        that.lines.push({
          line: line,
          tag: tag
        });
      },

      routeDrawPoints(origin, destination, id = null, autoFit = true) {
        let that = this;
        this.routePoints(origin, destination, (p) => {
          if (id == null)
            id = 0;

          let path = {
            id: id,
            path: p
          };
          that.drawPath(path);
          if (autoFit)
            that.fitPaths();
        }, () => {
          console.log('failed!');
        });
      },

      routePoints(origin, destination, successful, failed) {
        let that = this;

        const directionsService = new google.maps.DirectionsService();

        directionsService.route({
          origin: origin,
          destination: destination,
          travelMode: 'DRIVING'
        }, function (response, status) {
          if (status === 'OK') {

            if (response.routes.length > 0 && response.routes[0].legs.length > 0) {
              let route = response.routes[0];
              let path = [];
              route.legs[0].steps.forEach(function (point, index) {
                if (index == 0)
                  path.push({
                    lat: point.start_location.lat(),
                    lng: point.start_location.lng()
                  });
                path.push({
                  lat: point.end_location.lat(),
                  lng: point.end_location.lng()
                });
              });
              if (path.length == 0) {
                console.log('path length is 0');
                failed(null);
              } else {
                if (successful != null)
                  successful(path);
              }
            } else {
              console.log('ERROR: no routes available');
              failed(null);
            }
          } else {
            if (failed != null)
              failed(status);
          }
        });
      },

      clearMarker(id, tag = null) {
        let idx = null;

        this.markers.some(function (marker, _idx) {
          if (tag == null) {
            if (marker.id == id) {
              idx = _idx;
              return true;
            }
          } else {
            if (marker.tag != null && marker.tag == tag) {
              if (marker.id == id) {
                idx = _idx;
                return true;
              }
            }
          }
          return false;
        });

        if (idx != null) {
          console.log('found at ' + idx)

          this.markers[idx].marker.setMap(null);
          this.markers.splice(idx, 1);
          return true;
        }
        return false;
      },

      clearMarkers(tag = null) {
        let rebuilt = [];
        this.markers.forEach(function (marker) {
          if (tag == null) {
            marker.marker.setMap(null);
          } else {
            if (marker.tag != null && marker.tag == tag)
              marker.marker.setMap(null);
            else
              rebuilt.push(marker);
          }
        });
        if (tag == null)
          this.markers = [];
        else
          this.markers = rebuilt;
      },

      getMarkers(tag = null) {
        let set = [];
        this.markers.forEach(function (marker) {
          if (tag == null) {
            set.push(marker);
          } else {
            if (marker.tag != null && marker.tag == tag)
              set.push(marker);
          }
        });
        return set;
      },

      drawMarkers(markers, tag = null) {
        let that = this;

        const {Marker} = google.maps;

        const truckIcon = {
          url: "https://storage.googleapis.com/cargamos-215500.appspot.com/static/truck.png",
        };

        let pickupIcon = {
          url: "https://storage.googleapis.com/cargamos-215500.appspot.com/static/markerYellowPickup.png",
        };

        let dropoffIcon = {
          url: "https://storage.googleapis.com/cargamos-215500.appspot.com/static/markerYellowDropoff.png",
        };

        const yellowIcon = {
          url: "https://storage.googleapis.com/cargamos-215500.appspot.com/static/markerYellow.png",
          size: new google.maps.Size(47, 47),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(24, 47),
          labelOrigin: new google.maps.Point(23, 18),
        };

        markers.forEach(function (_marker, idx) {
          let position = {};
          if (Object.keys(_marker).includes('position')) {
            position = _marker.position;
          } else {
            position.lat = _marker.lat;
            position.lng = _marker.lng;
          }

          let marker = new Marker({
            idx: idx,
            position: position,
            marker: _marker,
            map: that.map,
            draggable: false,
            icon: POINT_MARKER_ICON_CONFIG
          });
          marker.addListener("click", () => {
            that.$emit("markerClicked", {
              marker: marker
            });
          });
          marker.addListener("dragend", () => {
            that.$emit("markerDragged", {
              marker: marker
            });
          });

          let markerId = null;
          if (Object.keys(_marker).includes('id')) {
            markerId = _marker.id;
          }

          let markerLabel = null;
          if (Object.keys(_marker).includes('label')) {
            markerLabel = _marker.label;
          }

          let markerType = null;
          if (Object.keys(_marker).includes('type')) {
            markerType = _marker.type;
          }

          switch (markerType) {
            case 'PICKUP':
              marker.setIcon(pickupIcon);
              break;

            case 'DROPOFF':
              marker.setIcon(dropoffIcon);
              break;

            case 'TRUCK':
              marker.setIcon(truckIcon);
              break;

            case 'DRAGGABLE':
              marker.setIcon(yellowIcon);
              if (markerLabel != null)
                marker.setLabel(markerLabel);
              marker.setDraggable(true);
              break;

            case 'LABEL':
            default:
              marker.setIcon(yellowIcon);
              if (markerLabel != null)
                marker.setLabel(markerLabel);
              break;
          }

          that.markers.push({
            marker: marker,
            tag: tag,
            id: markerId
          });
        });
      },

      updateMarker(idx, lat, lng) {
        if (this.markers.length > idx) {
          this.markers[idx].marker.setPosition(new google.maps.LatLng(lat, lng));
        }
      },

      fitMarker(idx) {
        if (this.markers.length > idx) {
          let min_lat = 9999;
          let min_lng = 9999;
          let max_lat = -9999;
          let max_lng = -9999;

          let pos = this.markers[idx].marker.getPosition();

          if (pos == null) {
            console.log('pos is null');
            return;
          }
          let lat = pos.lat();
          let lng = pos.lng();

          if (lat < min_lat) min_lat = lat;
          if (lng < min_lng) min_lng = lng;
          if (lat > max_lat) max_lat = lat;
          if (lng > max_lng) max_lng = lng;

          let bounds = {
            north: max_lat,
            south: min_lat,
            east: max_lng,
            west: min_lng
          };

          this.map.fitBounds(bounds);

          this.map.setZoom(16);
        }
      },

      fitMarkers(tag = null) {
        let that = this;

        let _markers = [];
        this.markers.forEach(function (marker) {
          if (tag == null) {
            _markers.push(marker.marker);
          } else {
            if (marker.tag != null && marker.tag == tag)
              _markers.push(marker.marker);
          }
        });

        if (tag != null)
          console.log('found ' + _markers.length + ' markers with tag ' + tag);

        if (_markers.length > 0) {
          let min_lat = 9999;
          let min_lng = 9999;
          let max_lat = -9999;
          let max_lng = -9999;
          this.markers.forEach(function (marker) {
            let pos = marker.marker.getPosition();

            if (pos == null) {
              console.log('pos is null');
              return;
            }
            let lat = pos.lat();
            let lng = pos.lng();

            if (lat < min_lat) min_lat = lat;
            if (lng < min_lng) min_lng = lng;
            if (lat > max_lat) max_lat = lat;
            if (lng > max_lng) max_lng = lng;
          });

          let bounds = {
            north: max_lat,
            south: min_lat,
            east: max_lng,
            west: min_lng
          };

          this.map.fitBounds(bounds);
        }

        if (_markers.length <= 1)
          this.map.setZoom(16);
      },

      fitPaths() {
        let that = this;

        if (this.lines.length > 0) {
          let min_lat = 9999;
          let min_lng = 9999;
          let max_lat = -9999;
          let max_lng = -9999;
          this.lines.forEach(function (line) {
            line.line.getPath().forEach(function (pos) {
              if (pos == null) {
                console.log('pos is null');
                return;
              }
              let lat = pos.lat();
              let lng = pos.lng();

              if (lat < min_lat) min_lat = lat;
              if (lng < min_lng) min_lng = lng;
              if (lat > max_lat) max_lat = lat;
              if (lng > max_lng) max_lng = lng;
            });
          });

          let bounds = {
            north: max_lat,
            south: min_lat,
            east: max_lng,
            west: min_lng
          };

          this.map.fitBounds(bounds);
        } else {
          this.map.setZoom(16);
        }
      },

      initializeMap() {
        googlePromise.then((google) => {
          const mapContainer = this.$refs.googleMap;
          this.map = new google.maps.Map(mapContainer, this.mapConfig);
        });
      }
    }
  }
</script>

<style scoped>
  .google-map {
    height: 100% !important;
  }
</style>
